jQuery(document).ready(function () {

  var controller = new ScrollMagic.Controller();


  var animateTwoColumn = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-fade")
  );

  animateTwoColumn.forEach(function (self) {
    
    jQuery("#" + self.id).imagesLoaded(function () {



      var two_column_col1 = self.querySelectorAll(
        ".two-col-fade .col-first .inner"
      );
      var two_column_col2 = self.querySelectorAll(
        ".two-col-fade .col-second"
      );

      var twoColumnScene = new TimelineMax();

      twoColumnScene
        .fromTo(
          two_column_col1,
          0.8,
          { opacity: 0, x: -10 },
          {
            opacity: 1,
            x: 0
          }
        )
        .fromTo(
          two_column_col2,
          0.8,
          { opacity: 0, x: -15 },
          {
            opacity: 1,
            x: 0
          },
          0.25,
          "-=.25"
        );

      var two_column_scene = new ScrollMagic.Scene({
        triggerElement: self,
        triggerHook: "onEnter",
        duration: 0,
        offset: 150
      })
        .setTween(twoColumnScene)
        .setClassToggle(self, "active")
        .addTo(controller);

    });
  });









/*
  var offset = 0;
  var services_height = jQuery('#services-container').height() - 400;

  var scene = new ScrollMagic.Scene({
    triggerElement: ".services-floater",
    duration: services_height,
    triggerHook: "onLeave",
    offset: offset
  }).setPin(".services-floater h2")
		.addIndicators({name: "1 (duration: "+services_height+")"}) // add indicators (requires plugin)
		.addTo(controller);
    */












/*
  jQuery(window).scroll(jQuery.throttle(65, updateStickyElements));
  jQuery(window).on("debouncedresize", function (event) {
    // Your event handler code goes here.
    updateStickyElements();
  });
  */

});

/*
var sticky_services_stuck = false;
var sticky_services_from_top = 0;
function updateStickyElements() { 

  var from_top = jQuery(window).scrollTop();

  if (jQuery('.services-floater h2').length > 0) {
    sticky_services_from_top = jQuery('.services-floater').offset().top;
  }

  if (sticky_services_from_top > 0) {
    if (!sticky_services_stuck && from_top >= sticky_services_from_top) {
      jQuery('.services-floater').addClass('sticky--pinned');
      sticky_services_stuck = true;
    }
    else if (sticky_services_stuck && from_top < sticky_services_from_top) {
      jQuery('.services-floater').removeClass('sticky--pinned');
      sticky_services_stuck = false;
    }

    // If it goes beyond the end of our set container, maybe just fade it out or something.
  }

}*/